import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { ClipboardService } from 'ngx-clipboard';
import { IrisAlertService } from '../alert/service/alert.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
interface ErrorI {
  date: string;
  method: string;
  requestId: string;
  status: number;
  url: string;
}

@Component({
  selector: 'iris-modal-about',
  styleUrls: ['./iris-modal-about.component.scss'],
  templateUrl: './iris-modal-about.component.html',
})
export class IrisModalAboutComponent extends IrisModalBase {

  readonly _STORAGE_KEY = 'RequestsErrors';

  status$ = this.envService.getInstanceStatus();
  env = this.envService.env;
  requestErrors = this.irisLocalStorageService.getItem(this._STORAGE_KEY) || [];

  constructor(
    dialogRef: MatDialogRef<IrisModalAboutComponent>,
    private readonly translate: TranslateService,
    private readonly irisLocalStorageService: IrisLocalStorageService,
    private readonly _clipboardService: ClipboardService,
    private readonly irisAlertService: IrisAlertService,
    private readonly envService: IrisEnvironmentService,
  ) {
    super(dialogRef);
    this.status$.subscribe();
  }

  getTitle(): string {
    return this.translate.instant('label.AboutIRIS').toUpperCase();
  }

  clearErrors(): void {
    this.irisLocalStorageService.removeItem(this._STORAGE_KEY);
    this.requestErrors = [];
  }

  onCopy(data: ErrorI | number | string, text: string): void {
    this._clipboardService.copyFromContent(JSON.stringify(data));
    this.onCopySuccess(text);
  }

  onCopySuccess(text: string): void {
    this.irisAlertService.success(this.translate.instant(text));
  }
}
