import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { IrisTimeFormatI } from './models/IrisTimeFormat';
import { IrisTimeService } from './services/time.service';
import { AbstractDatetime } from '../date';
import { IrisUserService } from '@iris/common/services/user.service';

enum TimeFormatType {
  Datetime = 'DATETIME',
  Date = 'DATE',
  Time = 'TIME',
}

export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

@Injectable({
  providedIn: 'root',
})
export class IrisTimeSandbox {
  timeFormats: IrisTimeFormatI[] = [];

  timeFormats$ = this.timeService.getTimeFormats().pipe(
    tap(formats => (this.timeFormats = formats)),
    share({
      connector: () => new ReplaySubject(1),
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false,
    }),
  );

  dateTimeFormats$: Observable<IrisTimeFormatI[]> = this.timeFormats$.pipe(
    map(formats => formats.filter(({ type }) => type === TimeFormatType.Datetime)),
  );

  dateFormats$: Observable<IrisTimeFormatI[]> = this.timeFormats$.pipe(
    map(formats => formats.filter(({ type }) => type === TimeFormatType.Date)),
  );

  timesFormats$: Observable<IrisTimeFormatI[]> = this.timeFormats$.pipe(
    map(formats => formats.filter(({ type }) => type === TimeFormatType.Time)),
  );

  private readonly pinnedTimeZones = ['Europe/Berlin', 'Europe/Vienna', 'Europe/Moscow', 'Europe/Prague', 'Europe/Warsaw', 'Europe/London'];

  private readonly DEFAULT_TIME_FORMAT = 'dd.MM.yyyy';

  constructor(
    private readonly timeService: IrisTimeService,
    private readonly userService: IrisUserService,
  ) {}

  get currentUser(): IdentUserI {
    return this.userService.me;
  }

  get currentUserTimeFormat(): IrisTimeFormatI {
    let userTimeCode = 'TIME';

    if (!this.currentUser.dateTimeFormatCode.includes('_YMD')) {
      if (this.currentUser.dateTimeFormatCode.includes('_HMS')) { userTimeCode += '_HMS'; }
      else if (this.currentUser.dateTimeFormatCode.includes('_HM')) { userTimeCode += '_HM'; }
      if (this.currentUser.dateTimeFormatCode.includes('_EN')) { userTimeCode += '_EN'; }
    } else {
      if (this.currentUser.dateTimeFormatCode.includes('_T_')) { userTimeCode += '_HM'; }
      else userTimeCode += '_HMS';
    }

    return this.timeFormats.find(format => format.keyCode === userTimeCode);
  }

  get currentUserDateFormat(): IrisTimeFormatI {
    let userDateCode = '';

    if (!this.currentUser.dateTimeFormatCode.includes('_YMD')) {
      userDateCode = 'DATE';

      if (this.currentUser.dateTimeFormatCode.includes('_NUMERIC')) { userDateCode += '_NUMERIC'; }
      if (this.currentUser.dateTimeFormatCode.includes('_HALFNUMERIC')) { userDateCode += '_HALFNUMERIC'; }
      if (this.currentUser.dateTimeFormatCode.includes('_DASH')) { userDateCode += '_DASH'; }
      if (this.currentUser.dateTimeFormatCode.includes('_EN')) { userDateCode += '_EN'; }
      if (this.currentUser.dateTimeFormatCode.includes('_DE')) { userDateCode += '_DE'; }
    } else {
      userDateCode = 'DATETIME_NUMERIC_YMD_DASH';
    }

    return this.timeFormats.find(format => format.keyCode === userDateCode);
  }

  get currentUserDateTimeFormat(): IrisTimeFormatI {
    return this.getDateTimeFormatByCode(this.currentUser.dateTimeFormatCode);
  }

  get currentUserTimeZone(): string {
    return this.currentUser.timeZone;
  }

  getDateTimeFormatByCode$(dateTimeFormatCode: string): Observable<IrisTimeFormatI> {
    return this.timeFormats$.pipe(
      map(formats => formats.find(({ keyCode }) => keyCode === dateTimeFormatCode)),
    );
  }

  getDateTimeFormatByCode(keyCode: string): IrisTimeFormatI {
    return (this.timeFormats ?? []).find(format => format.keyCode === keyCode);
  }

  getFormatStringByCode(dateTimeFormatCode: string): string {
    const format = this.getDateTimeFormatByCode(dateTimeFormatCode);
    return format?.luxonFormatString ?? this.DEFAULT_TIME_FORMAT;
  }

  getAllTimeZoneNames(): string[] {
    return Intl.supportedValuesOf('timeZone')
      .filter(zoneName => AbstractDatetime.nowLocal().setTimezone(zoneName).isValid());
  }

  getSortedAllTimeZones(): string[] {
    return this.pinnedTimeZones
      .concat(
        this.getAllTimeZoneNames().filter(tz => !this.pinnedTimeZones.includes(tz)),
      );
  }

  toTimeZoneAndFormat(dateTime: Date | string, zone: string, format: string): string {
    return AbstractDatetime.parse(dateTime).setTimezone(zone).format(format);
  }
}
