/* eslint-disable @typescript-eslint/no-explicit-any */
import { TranslateDefaultParser, getValue } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class IrisTranslationParser extends TranslateDefaultParser {
  getValue(target: any, key: string): any {
    const base = getValue(target, key);
    return typeof base === 'object' ? undefined : base;
  }
}
