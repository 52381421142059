import { Component, ChangeDetectionStrategy, Inject, OnInit, signal, computed } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { IrisFilesService, IrisFilePreviewType } from '@iris/common/modules/dms/services/files.service';
import { Router } from '@angular/router';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';
import { IrisDMSFileQueryParam, IrisDMSFileQueryParamAction } from '@iris/common/modules/dms/models/IrisDMSFileQueryParam';
import { goToPage } from '@iris/common/utils/router.utils';
import { PdfLoadedEvent, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, filter, shareReplay, switchMap, take } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

const OUT_OF_MEMORY_ERROR_TEXT = 'Out of memory';
const INVALID_STRUCTURE_ERROR_TEXT = 'Invalid PDF structure';

export interface FilesPreviewModalDataI {
  filesList: IrisDataRepoFileI[] | IrisDMSFileI[];
  file: IrisDataRepoFileI;
  openDms: boolean;
  showGoToFolder: boolean;
  saveRecentFile: boolean;
}

@Component({
  selector: 'files-preview-modal',
  templateUrl: './files-preview-modal.component.html',
  styleUrls: ['./files-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesPreviewModalComponent extends IrisModalBase implements OnInit {

  set filesList(filesList: IrisDataRepoFileI[] | IrisDMSFileI[]) {
    if (!!filesList && filesList.length > 0 && !!this.file) {
      this._filesList = filesList;
      this.currentIndex.set(this.filesList.findIndex(({ id }) => id == this.file.id));
    }

    if (this.currentIndex() === null || this.currentIndex() === -1) {
      this.currentIndex.set(0);
      this._filesList = [this.file];
    }
  }

  get filesList(): IrisDataRepoFileI[] | IrisDMSFileI[] {
    return this._filesList;
  }

  file: IrisDataRepoFileI;
  readonly currentIndex = signal<number>(null);
  showGoToFolder = true;
  readonly previewReady = signal(false);
  sidebarVisible: boolean;
  readonly isError = signal(false);

  private readonly currentFile = computed(() => this.filesList[this.currentIndex()] ?? this.file);
  private readonly currentFileId = computed(() => this.currentFile().id);
  readonly fileName = computed(() => this.currentFile().name);
  readonly currentFileMimeType = computed(() => this.currentFile().mimeType);
  readonly fileLink = computed(() => {
    if (this.file.id === this.currentFileId() && !!this.file.fileLink) {
      return this.file.fileLink;
    }
    return this.filesService.getFilePreviewUrl(this.currentFileId(), false, this.data.saveRecentFile);
  });
  readonly fileLink$ = toObservable(this.fileLink);
  readonly fileInfoHref = computed(() => this.filesService.generateFileInfoLink(this.currentFile()));

  readonly previewFileType = computed(() => this.filesService.getPreviewType(this.currentFile()));
  readonly isExcelFile = computed(() => this.filesService.isExcelFile(this.currentFileMimeType()));
  readonly isImage = computed(() => this.previewFileType() === IrisFilePreviewType.Image);
  readonly isPdf = computed(() => this.previewFileType() === IrisFilePreviewType.Pdf);
  readonly isDocument = computed(() => this.previewFileType() === IrisFilePreviewType.Document);

  readonly documentBlob$ = this.fileLink$.pipe(
    filter(() => this.isDocument()),
    distinctUntilChanged(),
    switchMap(fileLink => this.filesService.getFileLinkBase64String$(fileLink)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  private _filesList: IrisDataRepoFileI[] | IrisDMSFileI[] = [];
  private openDms = false;

  constructor(
    readonly dialogRef: MatDialogRef<FilesPreviewModalComponent>,
    private readonly filesService: IrisFilesService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MAT_DIALOG_DATA) public data: FilesPreviewModalDataI,
  ) {
    super(dialogRef);
    pdfDefaultOptions.annotationEditorMode = -1;
  }

  get listLength(): number {
    return this.filesList?.length;
  }

  get prevButtonDisabled(): boolean {
    if (this.isPdf() && this.sidebarVisible) { return true; }
    return false;
  }

  get nextButtonDisabled(): boolean {
    if (this.isPdf()) {
      const secondaryToolbar = this.document.getElementById('secondaryToolbar');
      const secondaryToolbarVisible = !(secondaryToolbar?.classList?.contains('hidden') ?? true);
      if (secondaryToolbarVisible) { return true; }
    }
    return false;
  };

  ngOnInit(): void {
    this.file = this.data.file;
    this.filesList = this.data.filesList;
    this.openDms = this.data.openDms;
    this.showGoToFolder = this.data.showGoToFolder;
    this.changeURL();
  }

  onPdfLoaded(ev: PdfLoadedEvent): void {
    if (ev.pagesCount > 0) {
      this._showPreview();
    }
  }

  onImageLoaded(_ev: Event): void {
    this._showPreview();
  }

  onPdfLoadingFailed(ev: Error): void {
    const m = ev.message;
    if (m?.includes(OUT_OF_MEMORY_ERROR_TEXT) || m?.includes(INVALID_STRUCTURE_ERROR_TEXT)) {
      this.isError.set(true);
      this.previewReady.set(true);
    }
  }

  nextFile(): void {
    let index = this.getNextIndex(this.currentIndex());
    while (this.filesService.isOfficeDocument(this.filesList[index].mimeType)) {
      index = this.getNextIndex(index);
    }
    this.currentIndex.set(index);
    this._showLoader();
    this.changeURL();
  }

  prevFile(): void {
    let index = this.getPreviousIndex(this.currentIndex());
    while (this.filesService.isOfficeDocument(this.filesList[index].mimeType)) {
      index = this.getPreviousIndex(index);
    }
    this.currentIndex.set(index);
    this._showLoader();
    this.changeURL();
  }

  changeURL(): void {
    if (!this.openDms) { return; }
    goToPage(this.router, [], {
      queryParams: { [IrisDMSFileQueryParam.File]: this.currentFileId() },
      queryParamsHandling: 'merge',
    });
  }

  goToParentFolder(): void {
    const parentFolderId = this.currentFile().parentId;

    goToPage(
      this.router,
      ['dms', 'folders', parentFolderId, 'files'],
      { queryParams: { file: this.currentFileId(), action: IrisDMSFileQueryParamAction.Select } },
    );

    this.closeWithoutResult();
  }

  downloadFile(): void {
    this.filesService.downloadFile(this.currentFileId(), this.fileName()).pipe(
      take(1),
    ).subscribe();
  }

  private _showPreview(): void {
    this.previewReady.set(true);
  }

  private _showLoader(): void {
    if (this.isPdf() || this.isImage()) {
      this.previewReady.set(false);
    }
  }

  private getNextIndex(index: number): number {
    if (index === (this.listLength - 1)) {
      return 0;
    } else {
      return ++index;
    }
  }

  private getPreviousIndex(index: number): number {
    if (index > 0) {
      return --index;
    } else {
      return this.listLength - 1;
    }
  }
}
