import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IrisEmailsGlobalEffects } from './global/emails-global.effects';
import { emailsCommonReducers } from './index';
import { IrisEmailsMessagesEffects } from './messages/emails-messages.effects';
import { IrisEmailsModalsModule } from './modals/emails-modals.module';
import { IrisModuleFilterModule } from '../../module-filter/module-filter.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(IrisEmailsSandboxModule.type, emailsCommonReducers),
    EffectsModule.forFeature([
      IrisEmailsGlobalEffects,
      IrisEmailsMessagesEffects,
    ]),
    IrisEmailsModalsModule,

    IrisModuleFilterModule,
  ],
})
export class IrisEmailsSandboxModule {
  static readonly type = 'emailsCommonModule';
}
