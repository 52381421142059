import { computed, inject, Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmailWellKnownFolderName, IrisEmailNavigationI } from '../../models/IrisEmailNavigation';
import * as emailsReducer from '../index';
import { IrisEmailsNavigationStore } from './emails-navigation.signal-store';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class IrisEmailsNavigationSandbox {
  private readonly emailsNavigationStore = inject(IrisEmailsNavigationStore);

  readonly navigationFolders = this.emailsNavigationStore.entities;

  readonly inboxNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.Inbox);
  readonly inboxNavigationItemId$ = toObservable(this.inboxNavigationItemId);
  readonly inboxNavigationItem = this.emailsNavigationStore.getFolderByShortcut(EmailWellKnownFolderName.Inbox);
  readonly draftNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.Drafts);
  readonly sentNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.SentItems);
  readonly deletedNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.DeletedItems);

  private readonly selectedMenuItemId$ = this.store.pipe(select(emailsReducer.getSelectedMenuItemId));
  private readonly selectedMenuItemId = toSignal(this.selectedMenuItemId$);
  readonly isDeletedFolderSelected = computed(() => {
    const selectedMenuItemId = this.selectedMenuItemId();
    const deletedNavigationItemId = this.deletedNavigationItemId();
    return selectedMenuItemId === deletedNavigationItemId;
  });

  readonly sortedNavigationFolders = this.emailsNavigationStore.sortedVisibleFlatNavigationTree;
  readonly childrenFolders = (parentFolderId: string): Signal<IrisEmailNavigationI[]> => this.emailsNavigationStore.getChildren(parentFolderId);
  
  constructor(
    private readonly store: Store<emailsReducer.EmailsCommonState>,
  ) { }

  getCustomFolders(): void {
    this.emailsNavigationStore.fetchCustomFolders$();
  }
  
  getChildrenFolders(parentFolderId: string): void {
    this.emailsNavigationStore.fetchChildrenFolders$(parentFolderId);
  }

  getFolderIdByShortcut(folderShortcut: EmailWellKnownFolderName): Signal<string> {
    return this.emailsNavigationStore.getFolderIdByShortcut(folderShortcut);
  }

  fetchCustomFolder(folderId: string | EmailWellKnownFolderName): void {
    this.emailsNavigationStore.fetchCustomFolder$(folderId);
  }

  fetchInboxFolder(): void {
    this.fetchCustomFolder(EmailWellKnownFolderName.Inbox);
  }

  collapseFolder(folderId: string): void {
    this.emailsNavigationStore.collapseFolder(folderId);
  }
}
