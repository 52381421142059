
export function resolveUrlPlaceholders(url: string, params: { [key: string]: string }, aliasMappings?: { [key: string]: string }): string {
  const placeholders = [...new Set(url.match(/{([^}]+)}/g))];
  placeholders.forEach(pl => {
    const alias = pl.substring(1, pl.length - 1);
    const aliasMapping = (aliasMappings || { })[alias] || alias;
    url = url.replace(pl, params[aliasMapping] ?? '');
  });
  url = url.replace(/[/]+/g, '/');
  url = url.replace(/[/]+$/g, '');
  return url;
}

export function getUi2SubPathOrNull(url: string): string {
  if (!url) {
    return null;
  }
  const newAngularUrlQualifier = '/ui2/';
  const ui2index = url.indexOf(newAngularUrlQualifier);
  if (ui2index !== -1) {
    return url.substring(ui2index + newAngularUrlQualifier.length);
  }
  return null;
}

export const extendUrl = (url: string, query: string): string => {
  return url += (url.indexOf('?') > 0 ? '&' : '?') + query;
};

export const IrisMicroservices = {
  DROPBOX: 'dropbox',
  STRATHEK: 'strathek',
  STRASYNC: 'strasync',
  NOTIFICATIONS: 'notifications',
  OLD_SYNCLIENT: 'synclient',
  ELASTIC: 'elastic',
  SYNCLIENT: 'sync-master',
  DATA_IMPORT: 'sensordata-import',
  BIM: 'bimm',
  MARKETPLACE: 'marketplace',
  DMS2: 'dms2',
  IDP: 'idp',
  SYSTEMLOGS: 'system-logs',
  FORMS: 'forms',
};

export class MicroservicesUtils {

  public static MICROSERVICES = {
    [IrisMicroservices.DROPBOX]: 8088,
    [IrisMicroservices.STRATHEK]: 8089,
    [IrisMicroservices.STRASYNC]: 8089,
    [IrisMicroservices.NOTIFICATIONS]: 8090,
    [IrisMicroservices.OLD_SYNCLIENT]: 8101,
    [IrisMicroservices.ELASTIC]: 8102,
    [IrisMicroservices.SYNCLIENT]: 8333,
    [IrisMicroservices.DATA_IMPORT]: 8334,
    [IrisMicroservices.BIM]: 37080,
    [IrisMicroservices.MARKETPLACE]: 5002,
    [IrisMicroservices.DMS2]: 8092,
    [IrisMicroservices.IDP]: 8099,
    [IrisMicroservices.SYSTEMLOGS]: 8091,
    [IrisMicroservices.FORMS]: 8085,
  };

  public static MS_WITH_ERROR_NOTIFICATION = [
    IrisMicroservices.DROPBOX,
    IrisMicroservices.SYNCLIENT,
    IrisMicroservices.OLD_SYNCLIENT,
    IrisMicroservices.STRATHEK,
    IrisMicroservices.STRASYNC,
  ];

  public static getMicroserviceBaseUrl(appName: string, env: IrisEnv): string {
    let baseUrl = env.apiUrl;

    baseUrl = `${baseUrl}/${appName}`;

    const instanceId = env.instanceId;
    if (instanceId !== '') {
      return `${baseUrl}/${instanceId}`;
    } else {
      return baseUrl;
    }
  }

  public static isPrivateUrl(url): boolean {
    try {
      const urlObj = new URL(url);
      return this.isPrivateHost(urlObj.hostname);
    } catch {
      return false;
    }
  }

  public static isPrivateHost(host): boolean {
    if (host === 'localhost' || host === '127.0.0.1') {
      return true;
    }
    const parts = host.split('.');
    if (parts.length !== 4) {
      return false;
    }
    try {
      return parts[0] === '10' ||
        (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
        (parts[0] === '192' && parts[1] === '168');
    } catch (exc) {
      return false;
    }
  }

}

export function withQueryParam(url: string, paramName: string, paramValue: string): string {
  return paramValue ? `${url}${url.indexOf('?') >= 0 ? '&' : '?'}${paramName}=${paramValue}` : url;
}
