import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IrisEmailMessage, IrisEmailMessageI } from '../../models/IrisEmail';

import {
  MessagesLoadingStart,
  MessagesLoadingEnd,
  AddEmailMessagesSuccess,
  MarkMessageAsReadSuccess,
  MarkMessageAsUnreadSuccess,
  MoveMessagesToFolderStart,
  GetMessageAttachmentsSuccess,
  RemoveEmailMessageSuccess,
  RemoveSelectedEmailMessagesSuccess,
  GetEmailsMessageSuccess,
  SetMessageReadMarkBulkSuccess,
  GetEmailsMessageConversationSuccess,
} from './emails-messages.actions';
import { difference, isNil } from 'lodash';

export interface EmailsMessagestState extends EntityState<IrisEmailMessageI> {
  messagesLoading: boolean;
}

export const adapter: EntityAdapter<IrisEmailMessageI> = createEntityAdapter<IrisEmailMessageI>();

export const initialState: EmailsMessagestState = adapter.getInitialState({
  messagesLoading: false,
});

export const emailsMessagesReducer = createReducer(
  initialState,
  on(MessagesLoadingStart, state => ({ ...state,  messagesLoading: true })),
  on(MessagesLoadingEnd, state => ({ ...state,  messagesLoading: false })),
  on(AddEmailMessagesSuccess, (state, { messages, replace, offset, limit }) => {
    const mappedMessages = (messages ?? []).map(message => new IrisEmailMessage().fromResponse(message));
    if (replace) { return adapter.setAll(mappedMessages, state); }
    if (!isNil(offset) && !isNil(limit)) {
      const existingMessages = selectAll(state);
      existingMessages.splice(offset, limit, ...mappedMessages);
      return adapter.setAll(existingMessages, state);
    }
    const updatedState = adapter.upsertMany(mappedMessages, state);
    return updatedState;
  }),
  on(
    MarkMessageAsReadSuccess,
    MarkMessageAsUnreadSuccess,
    (state, { message }) => adapter.updateOne({ id: message.id, changes: { markedAsRead: message.markedAsRead } }, state),
  ),
  on(GetEmailsMessageSuccess, (state, { message }) => {
    const existMessage = state.entities[message.id];
    const attachments = message.hasAttachments ? existMessage?.attachments ?? message?.attachments : [];
    return adapter.upsertOne({ ...message, attachments }, state);
  }),
  on(MoveMessagesToFolderStart, (state, { messagesIds }) => adapter.removeMany(messagesIds, state)),
  on(GetMessageAttachmentsSuccess, (state, { messageId, attachments }) => {
    return adapter.updateOne({ id: messageId, changes: { attachments } }, state);
  }),
  on(RemoveEmailMessageSuccess, (state, { messageId }) => adapter.removeOne(messageId, state)),
  on(RemoveSelectedEmailMessagesSuccess, (state, { selectedMessagesIds, unselectedMessagesIds }) => {
    if (selectedMessagesIds.length) {
      return adapter.removeMany(selectedMessagesIds, state);
    }

    const allMessagesIds = selectIds(state).map(String);
    const messagesToRemove = difference(allMessagesIds, unselectedMessagesIds);
    return adapter.removeMany(messagesToRemove, state);
  }),
  on(SetMessageReadMarkBulkSuccess, (state, { read, selectedMessagesIds, unselectedMessagesIds }) => {
    const messagesIds = selectIds(state).map(String);
    const messagesToUpdate = messagesIds
      .filter(messageId => {
        return unselectedMessagesIds.length
          ? !unselectedMessagesIds.includes(messageId)
          : !selectedMessagesIds.length || selectedMessagesIds.includes(messageId);
      })
      .map(messageId => ({ id: messageId, changes: { markedAsRead: read } }));
    return adapter.updateMany(messagesToUpdate, state);
  }),
  on(GetEmailsMessageConversationSuccess, (state, { messageId, relatedMessages }) => {
    return adapter.updateOne({
      id: messageId,
      changes: { relatedMessages: relatedMessages.filter(message => message.id !== messageId ) },
    }, state);
  }),
);

export function reducer(state: EmailsMessagestState | undefined, action: Action): EmailsMessagestState {
  return emailsMessagesReducer(state, action);
}

const { selectAll, selectIds } = adapter.getSelectors();

export const getMessages = (state: EmailsMessagestState): IrisEmailMessageI[] => selectAll(state)
  .map(message => new IrisEmailMessage(message));
export const getMessageById = (state: EmailsMessagestState, messageId: string): IrisEmailMessageI => {
  const message = state.entities[messageId];
  return message ? new IrisEmailMessage(message) : message;
};
export const getMessagesByIds = (state: EmailsMessagestState, messagesIds: string[]): IrisEmailMessageI[] => messagesIds
  .map(messageId => state.entities[messageId])
  .filter(Boolean)
  .map(message => new IrisEmailMessage(message));
export const getMessagesFlatList = (state: EmailsMessagestState): IrisEmailMessageI[] =>
  selectAll(state).flatMap((message) => [message, ...(message.relatedMessages || [])]);
