import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EmailCategory } from '@iris/common/modules/emails/models/email-categories';
import { IrisEmailMessageI } from '@iris/common/modules/emails/models/IrisEmail';
import { IrisEmailsGlobalSandbox } from '@iris/common/modules/emails/sandbox/global/emails-global.sandbox';
import { IrisEmailsNavigationSandbox } from '@iris/common/modules/emails/sandbox/navigation/emails-navigation.sandbox';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { take, withLatestFrom, map, shareReplay, Observable, switchMap, filter, debounceTime } from 'rxjs';
import {
  DRAFT_MESSAGE_PATH_NAME,
  FORWARDED_MESSAGE_QUERY_NAME,
  IrisEmailsTabType,
  NEW_MESSAGE_PATH_NAME,
  REPLIED_MESSAGE_QUERY_NAME,
  REPLY_ALL_QUERY_NAME,
  ROOT_EMAILS_PATH,
} from '../utils/emails-routing.utils';

@Injectable({ providedIn: 'root' })
export class IrisEmailsRoutingService {

  baseEmailsPath$ = this.globalSandbox.currentProjectId$.pipe(
    map(projectId => {
      if (!projectId) { return [ROOT_EMAILS_PATH]; }
      return [ROOT_EMAILS_PATH, projectId];
    }),
    debounceTime(0),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );
    
  constructor(
    private readonly router: Router,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly emailsGlobalSandbox: IrisEmailsGlobalSandbox,
    private readonly emailsNavigationSandbox: IrisEmailsNavigationSandbox,
  ) {}
  
  openRootPage(): Promise<boolean> {
    return this.router.navigate([ROOT_EMAILS_PATH]);
  }

  openSendEmailPage(extras?: NavigationExtras): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      withLatestFrom(this.emailsGlobalSandbox.selectedMenuItemId$),
      switchMap(([basePathSegments, menuItemId]) => this.router.navigate([
        ...basePathSegments,
        menuItemId ?? EmailCategory.Inbox.toLowerCase(),
        NEW_MESSAGE_PATH_NAME,
      ], extras)),
    );
  }

  openForwardEmailPage(forwardedMessageId: string): Observable<boolean> {
    return this.openSendEmailPage({ queryParams: { [FORWARDED_MESSAGE_QUERY_NAME]: forwardedMessageId } });
  }

  openReplyToEmailPage(repliedMessageId: string, replyAll?: boolean): Observable<boolean> {
    return this.openSendEmailPage({ queryParams: {
      [REPLIED_MESSAGE_QUERY_NAME]: repliedMessageId,
      [REPLY_ALL_QUERY_NAME]: replyAll,
    } });
  }

  openInboxPage(extras?: NavigationExtras): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap((basePathSegments) => this.emailsNavigationSandbox.inboxNavigationItemId$.pipe(
        filter(Boolean),
        take(1),
        map(inboxNavigationItemId => ({ basePathSegments, inboxNavigationItemId })),
      )),
      switchMap(({ basePathSegments, inboxNavigationItemId }) => this.router.navigate([
        ...basePathSegments,
        inboxNavigationItemId,
      ], extras)),
    );
  }

  openDraftPage(): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap(basePathSegments => {
        const draftNavigationItemId = this.emailsNavigationSandbox.draftNavigationItemId();
        return this.router.navigate([
          ...basePathSegments,
          draftNavigationItemId,
        ]);
      }),
    );
  }

  closeSelectedEmail(): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      withLatestFrom(this.emailsGlobalSandbox.selectedMenuItemId$),
      switchMap(([basePathSegments, selectedMenuItemId]) => this.router.navigate([
        ...basePathSegments,
        selectedMenuItemId,
      ])),
    );
  }

  openParentPage(route: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: route });
  }

  openMainPage(): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap(basePathSegments => this.router.navigate(basePathSegments)),
    );
  }

  openDocumentsPage(extras?: NavigationExtras): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap(basePathSegments => this.router.navigate([
        ...basePathSegments,
        IrisEmailsTabType.Documents.toLowerCase(),
      ], extras)),
    );
  }

  openPageByFolderId(folderId: string, extras?: NavigationExtras): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap(basePathSegments => this.router.navigate([
        ...basePathSegments,
        folderId,
      ], extras)),
    );
  }
  
  openMessagePage(message: IrisEmailMessageI, extras?: NavigationExtras): Observable<boolean> {
    return this.baseEmailsPath$.pipe(
      take(1),
      switchMap(basePathSegments => {
        const commands = [
          ...basePathSegments,
          message.parentFolderId,
        ];

        if (message.isDraft) {
          commands.push(DRAFT_MESSAGE_PATH_NAME);
        }

        commands.push(message.id);
        return this.router.navigate(commands, extras);
      }),
    );
  }
}
