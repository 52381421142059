import { toggleCssClass } from '@iris/common/modules/fields/ng-select-field/common/ng-select-field.utils';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { defaultAppendQueryParamsFn } from '@iris/modules/module-filters-v2/query-params';
import { filterValueArrayIsEmpty } from '@iris/modules/module-filters-v2/utils';
import { NgSelectComponent } from '@natlex/ng-select';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function selectFilterMeta<TEntity>(
  propertyId: string,
  label: string,
  items: TEntity[],
  bindLabel: keyof TEntity,
  bindValue: keyof TEntity,
  isStrict: boolean,
  disableSearch?: boolean,
  dropdownWidthNative = true,
  appendBody = true,
): IrisFilterPropertyMeta {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: defaultAppendQueryParamsFn<string>(
      propertyId,
      value => [isStrict ? value : `%${value}%`],
      t => t.strict(isStrict),
    ),
    filterRenderingOptions: {
      component: NgSelectComponent,
      getFilterValueFn: (componentInstance: NgSelectComponent): Observable<TEntity> => {
        return componentInstance.changeEvent.pipe(
          switchMap(() => {
            return !componentInstance.selectedValues.length ? [undefined] : componentInstance.selectedValues.map((item) => item[bindValue]);
          }),
        );
      },
      initComponentFn: (componentInstance: NgSelectComponent): Observable<void> => {
        componentInstance.items = items;
        componentInstance.searchable = !disableSearch;
        if (appendBody) { componentInstance.appendTo = 'body'; }
        componentInstance.bindLabel = String(bindLabel);
        componentInstance.bindValue = String(bindValue);
        componentInstance.classes = toggleCssClass(componentInstance.classes, 'ng-select-inline');
        if (dropdownWidthNative) {
          componentInstance.classes = toggleCssClass(componentInstance.classes, 'dropdown-width-native');
        }
        componentInstance.refreshItems();
        return of(null);
      },
      setValueFn: (componentInstance: NgSelectComponent, value: number | number[]): void => componentInstance.writeValue(value),
    },
    filterValueIsEmpty: filterValueArrayIsEmpty,
  };
}
