import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { WindowRef } from '@iris/common/utils/window.ref';
import { MicroservicesUtils } from '@iris/common/utils/url.utils';

@Injectable({ providedIn: 'root' })
export class IrisInfrastructureService {

  private readonly operatingSystems: { id: string; name: string; index?: number }[];
  private readonly browsers: { id: string; name: string; index?: number }[];
  private readonly tabletAgentsRegexp: RegExp[] = [
    /Tablet/i,
    /iPad/i,
    /iPod/i,
    /android/i,
    /playbook/i,
    /silk/i,
  ];
  private readonly mobileDevicesRegexps: RegExp[] = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  private readonly tabletScreenBounds = {
    width: 800,
  };

  constructor(private readonly translateService: TranslateService,
              private readonly irisAlertService: IrisAlertService,
              private readonly windowRef: WindowRef) {
    this.operatingSystems = [
      { id: 'WINDOWS7', name: 'Windows 7' },
      { id: 'WINDOWS8', name: 'Windows 8' },
      { id: 'WINDOWS81', name: 'Windows 8.1' },
      { id: 'WINDOWS10', name: 'Windows 10' },
      { id: 'MACOS', name: 'Mac OS' },
      { id: 'MACOSX', name: 'Mac OS X' },
      { id: 'LINUX', name: 'Linux' },
      { id: 'IOS', name: 'iOS' },
      { id: 'ANDROID', name: 'Android' },
      { id: 'OTHER', name: this.translateService.instant('label.Other') },
    ];

    this.browsers = [
      { id: 'FIREFOX', name: 'Firefox' },
      { id: 'CHROME', name: 'Chrome' },
      { id: 'IE', name: 'Internet Explorer' },
      { id: 'EDGE', name: 'Microsoft Edge' },
      { id: 'OPERA', name: 'Opera' },
      { id: 'SAFARI', name: 'Safari' },
      { id: 'OTHER', name: this.translateService.instant('label.Other') },
    ];

    [this.operatingSystems, this.browsers].forEach(list => {
      list.forEach((val, index) => val.index = index);
    });
  }

  get isMobile(): boolean {
    return this.mobileDevicesRegexps.some(device =>
      this.windowRef.nativeWindow.navigator.userAgent.match(device),
    );
  }

  get isTablet(): boolean {
    return (this.isAgentMatchTablet() || this.isIpadOS()) && window.innerWidth > this.tabletScreenBounds.width;
  }

  private isAgentMatchTablet(): boolean {
    return this.tabletAgentsRegexp.some(device => this.windowRef.nativeWindow.navigator.userAgent.match(device));
  }

  // Since 2019 difference between iPadPro and the other platforms is that iPadPro is touch enabled
  private isIpadOS(): boolean {
    return !!this.windowRef.nativeWindow.navigator.maxTouchPoints &&
      this.windowRef.nativeWindow.navigator.maxTouchPoints > 2 &&
      this.windowRef.nativeWindow.navigator.platform.includes('MacIntel');
  }

  getBrowserInfo() {
    const objAgent = navigator.userAgent;
    let objbrowserName = navigator.appName;
    let objfullVersion = '' + parseFloat(navigator.appVersion);
    let objBrMajorVersion = parseInt(navigator.appVersion, 10);
    let objOffsetName: number, objOffsetVersion: number, ix: number;

    // In Chrome
    if ((objOffsetVersion = objAgent.indexOf('Chrome')) != -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    } else if ((objOffsetVersion = objAgent.indexOf('MSIE')) != -1) {
      objbrowserName = 'Microsoft Internet Explorer';
      objfullVersion = objAgent.substring(objOffsetVersion + 5);
    } else if ((objOffsetVersion = objAgent.indexOf('Firefox')) != -1) {
      objbrowserName = 'Firefox';
      objfullVersion = navigator.userAgent.substr(navigator.userAgent.lastIndexOf('/') + 1);
    } else if ((objOffsetVersion = objAgent.indexOf('Safari')) != -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) != -1) {
        objfullVersion = objAgent.substring(objOffsetVersion + 8);
      }
    } else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) <
      (objOffsetVersion = objAgent.lastIndexOf('/'))) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() == objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present
    if ((ix = objfullVersion.indexOf(';')) != -1) {
      objfullVersion = objfullVersion.substring(0, ix);
    }
    if ((ix = objfullVersion.indexOf(' ')) != -1) {
      objfullVersion = objfullVersion.substring(0, ix);
    }

    objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
      objBrMajorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      browserName: objbrowserName,
      fullVersion: objfullVersion,
      majorVersion: objBrMajorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent,
    };

  }

  copyToClipboard(link: string) {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('value', link);
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenInput);
    this.irisAlertService.success(this.translateService.instant('text.ClipboardCopySuccess'));
  }

  isPrivateServer(): boolean {
    return MicroservicesUtils.isPrivateHost(this.windowRef.nativeWindow.location.hostname);
  }
}
